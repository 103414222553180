import request from "@/core/services/axios";
import qs from "qs";

export function monitorRateList(query) {
  return request({
    url: "/exam/monitor/rate/list",
    method: "get",
    params: query,
  });
}

// 阅卷监控-查看详细进度
export function monitorRateDetail(query) {
  return request({
    url: "/exam/monitor/detail",
    method: "get",
    params: query,
  });
}

// 阅卷监控-学校详细进度
export function qustionworkload(query) {
  return request({
    url: "/exam/monitor/phone/qustion/workload",
    method: "get",
    params: query,
  });
}

// 阅卷监控-学校详细进度 新版
export function singleWorkload(query) {
  return request({
    url: "/exam/monitor/single/workload",
    method: "get",
    params: query,
  });
}

// 获取联考进度监控列表v2
export function unionRatev2(query) {
  return request({
    url: "/exam/monitor/union/ratev2",
    method: "get",
    params: query,
  });
}
// 获取联考进度监控列表v2 分组
export function unionGrouprate(query) {
  return request({
    url: "/exam/monitor/union/grouprate",
    method: "get",
    params: query,
  });
}

// 获取分组进度
export function groupWorkload(query) {
  return request({
    url: "/exam/monitor/group/workload",
    method: "get",
    params: query,
  });
}

// 获取试卷题目阅卷老师
export function questionTeacher(query) {
  return request({
    url: "/exam/exammark/question/teacher",
    method: "get",
    params: query,
  });
}

// 联考-学校阅卷进度
export function schoolRate(query) {
  return request({
    url: "/exam/monitor/school/rate",
    method: "get",
    params: query,
  });
}

// 联考-进度监控-已批阅学生列表-教师批阅列表v2
export function teacherMarkV2(query) {
  return request({
    url: "/exam/monitor/union/teacher/markV2",
    method: "get",
    params: query,
  });
}

// 联考-导出学校阅卷进度
export function exportSchoolRate(query) {
  return request({
    url: "/exam/monitor/export/school/rate",
    method: "get",
    params: query,
  });
}

// 获取联考进度监控列表
export function unionRate(query) {
  return request({
    url: "/exam/monitor/union/rate",
    method: "get",
    params: query,
  });
}

// 联考-导出阅卷进度
export function exportRate(query) {
  return request({
    url: "/exam/monitor/export/rate",
    method: "get",
    params: query,
  });
}

// 联考-进度监控-未批阅学生列表-未完成批阅的学生列表
export function teacherNotmark(data, query) {
  let dataObj = qs.stringify(query);
  return request({
    url: "/exam/monitor/union/teacher/notmark?" + dataObj,
    method: "post",
    data: data,
  });
}

// 联考-进度监控-已批阅学生列表-教师批阅列表
export function teacherMark(data, query) {
  let dataObj = qs.stringify(query);
  return request({
    url: "/exam/monitor/union/teacher/mark?" + dataObj,
    method: "post",
    data: data,
  });
}

// 阅卷监控-查看详细进度
export function monitorMarkingList(query) {
  return request({
    url: "/exam/monitor/marking/list",
    method: "get",
    params: query,
  });
}

// 获取质量列表接口
export function monitorQualityList(query) {
  return request({
    url: "/exam/monitor/quality/list",
    method: "get",
    params: query,
  });
}

// 导出质量列表接口
export function exportQuality(query) {
  return request({
    url: "/exam/monitor/export/quality",
    method: "get",
    params: query,
  });
}

// 阅卷监控-根据试卷id获取题目列表 获取阅卷老师
export function getQuestionTeacher(query) {
  return request({
    url: "/exam/monitor/question/teacher",
    method: "get",
    params: query,
  });
}

// 获取评分曲线数据
export function scoreCurve(query) {
  return request({
    url: "/exam/monitor/score/curve",
    method: "get",
    params: query,
  });
}

// 获取阅卷报警数量
export function markabnormolCount(query) {
  return request({
    url: "/exam/markabnormol/count",
    method: "get",
    params: query,
  });
}

// 获取阅卷报警列表接口
export function markabnormolList(query) {
  return request({
    url: "/exam/markabnormol/list",
    method: "get",
    params: query,
  });
}

// 多评监控查询条件获取
export function multipleCondition(query) {
  return request({
    url: "/exam/monitor/multiple/condition",
    method: "get",
    params: query,
  });
}

// 阅卷监控-按班级批阅-查看详细进度
export function classnumDetail(query) {
  return request({
    url: "/exam/monitor/classnum/detail",
    method: "get",
    params: query,
  });
}

// 获取多评监控数据
export function multipleList(query) {
  return request({
    url: "/exam/monitor/multiple/list",
    method: "get",
    params: query,
  });
}

// 多评监控数据详细
export function multipleDetail(query) {
  return request({
    url: "/exam/monitor/multiple/detail",
    method: "get",
    params: query,
  });
}

// 阅卷监控-抽查阅卷列表数据
export function monitorCheckList(query) {
  return request({
    url: "/exam/monitor/check/list",
    method: "get",
    params: query,
  });
}

// 阅卷监控-抽查阅卷详细接口
export function monitorCheckDetail(query) {
  return request({
    url: "/exam/monitor/check/detail",
    method: "get",
    params: query,
  });
}

// 阅卷监控-抽查结果列表
export function monitorCheckResult(query) {
  return request({
    url: "/exam/monitor/check/result",
    method: "get",
    params: query,
  });
}

// 通过考试id获取老师权限列表
export function teacherPermission(query) {
  return request({
    url: "/exam/monitor/teacher/permission",
    method: "get",
    params: query,
  });
}

// 通过考试id获取老师权限列表 联考
export function teacherPermissionV2(query) {
  return request({
    url: "/exam/monitor/teacher/permissionV2",
    method: "get",
    params: query,
  });
}

// 设置绑定批阅-查看阅卷区域详细
export function exammarkDetail(query) {
  return request({
    url: "/exam/exammark/detail",
    method: "get",
    params: query,
  });
}

// 导出多评监控数据
export function multipleExport(query) {
  return request({
    url: "/exam/monitor/multiple/export",
    method: "get",
    params: query,
  });
}

// 设置绑定批阅-添加阅卷区域
export function postExammarkDetail(data) {
  return request({
    url: "/exam/exammark/add/region",
    method: "post",
    data: data,
  });
}

// 阅卷监控-根据考试ID获取考生的班级
export function monitorClass(query) {
  return request({
    url: "/exam/monitor/class",
    method: "get",
    params: query,
  });
}

// 获取此次登录老师是否含有小题组长权限
export function leaderPermission(query) {
  return request({
    url: "/exam/exammark/leader/permission",
    method: "get",
    params: query,
  });
}

// 阅卷报警-获取学生答题卡信息（调整主观题阅卷区域）
export function markabnormolStudentInfo(data) {
  return request({
    url: "/exam/markabnormol/student/info",
    method: "post",
    data: data,
  });
}

// 释放老师阅卷试题
export function monitorRemoveMarking(data) {
  return request({
    url: "/exam/monitor/remove/marking",
    method: "delete",
    data: data,
  });
}

// 阅卷监控-阅卷重评
export function monitorBack(data) {
  return request({
    url: "/exam/markabnormol/back",
    method: "put",
    data: data,
  });
}

// 阅卷监控-阅卷重评v2
export function monitorBack2(data) {
  return request({
    url: "/exam/markabnormol/backv2",
    method: "put",
    data: data,
  });
}

// 处理阅卷报警(id与examPaperId二选一)
export function markabnormolHandel(data) {
  return request({
    url: "/exam/markabnormol/handel",
    method: "post",
    data: data,
  });
}

// 处理阅卷报警(id与examPaperId二选一)
export function monitorCheckSave(data) {
  return request({
    url: "/exam/monitor/check/save",
    method: "post",
    data: data,
  });
}

// 联考-阅卷报警-异常报警回收
export function questionUnlock(data) {
  return request({
    url: "/exam/markabnormol/question/unlock",
    method: "post",
    data: data,
  });
}

// 批量释放老师阅卷试题
export function removeMarkingList(data) {
  let dataObj = qs.stringify(data);
  return request({
    url: "/exam/monitor/remove/marking/list/?" + dataObj,
    method: "delete",
    // data: data,
  });
}

// 批量释放老师阅卷试题
export function arbitration(query) {
  // let dataObj = qs.stringify(data);
  return request({
    url: "/exam/monitor/arbitration",
    method: "get",
    params: query,
  });
}

// 阅卷报警- 获取单个试题裁切详情
export function markabnormolDetail(query) {
  return request({
    url: "/exam/markabnormol/detail",
    method: "get",
    params: query,
  });
}

// 联考-阅卷报警-循环拿题
export function markabnormolQuestion(query) {
  return request({
    url: "/exam/markabnormol/question",
    method: "get",
    params: query,
  });
}

// 阅卷报警- 获取单个试题裁切详情
export function updateArea(data) {
  return request({
    url: "/exam/markabnormol/update/area",
    method: "post",
    data: data,
  });
}

// 阅卷报警- 裁切学生所有试题
export function newUpdateArea(data) {
  return request({
    url: "/exam/markabnormol/new/update/area",
    method: "post",
    data: data,
  });
}
