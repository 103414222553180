<template>
  <div class="refreshTime">
    <div class="right-box" @click="getNewTime(1)">
      读取数据时间：{{ refTime | normalTime() }}
      <el-button type="text" icon="el-icon-refresh-right">刷新</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RefreshTime",
  components: {},
  data() {
    return { refTime: "" };
  },
  created() {
    this.getNewTime();
  },
  methods: {
    getNewTime(type) {
      this.refTime = new Date();
      this.isRouterAlive = new Date().getTime();
      if (type) {
        this.$parent.getNewItem();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.refreshTime {
  display: inline-block;
  .right-box {
    color: #080a09;
    margin-bottom: 18px;
    font-size: 14px;
    cursor: pointer;
  }

  .el-icon-refresh-right {
    color: $primary-color;
  }
}
</style>
